<template>
  <div
    :style="{ top: `${windowHeight - 49}px` }"
    class="bottom-filter-wrapper"
    :class="bottomFilterSheet || showInfoSheet ? 'showFilterButton' : ''"
  >
    <div class="places-visited-wrapper" @click="toggleGiftDiaog">
      <img :src="isNewVoucherReceived ? incompleVoucher : incompleVoucher" alt="voucher Icon" />
      <span>{{ placeVisitedCount }}/{{ maximumLimit }}</span>
    </div>
    <v-btn class="filterBtn" @click="bottomSheetHandler">FILTER</v-btn>
  </div>
</template>
<script>
import incompleVoucher from '@/assets/bottomBar/bttn_gift_incomplete.png';
import compleVoucher from '@/assets/bottomBar/bttn_gift_complete.png';
import { mapState } from 'vuex';
export default {
  name: 'BottomBar',
  data() {
    return {
      incompleVoucher,
      compleVoucher,
    };
  },
  computed: {
    ...mapState({
      placeVisitedCount: (state) => state.placeVisitedCount,
    }),
    // computed property to check if the voucher is received
    isNewVoucherReceived() {
      return this.placeVisitedCount == this.maximumLimit;
    },
  },
  watch: {
    // watching the window height when it gets updated
    windowHeight(val) {
      console.log(val);
    },
  },
  props: {
    bottomFilterSheet: {
      type: Boolean,
      required: true,
    },
    showInfoSheet: {
      type: Boolean,
      required: true,
    },
    bottomSheetHandler: {
      type: Function,
      required: true,
    },
    toggleGiftDiaog: {
      type: Function,
      required: true,
    },
    maximumLimit: {
      type: Number,
      required: true,
    },
    windowHeight: {
      type: Number,
      required: true,
    },
  },
};
</script>
