<template>
  <v-bottom-sheet v-model="sheet" persistent content-class="filterSheet" :retain-focus="false">
    <v-sheet class="text-center" height="288px">
      <div class="flex over-lapping-box" v-for="(item, key) in filterList" :key="key">
        <div class="location-type flex">
          <img :src="item.isActive ? item.icon : item.grayIcon" alt="Icon" class="icon" />
          <p class="icon-name">{{ item.type }}</p>
        </div>
        <div class="apply-filter-slider flex">
          <v-switch
            :input-value="item.isActive"
            @change="onApplyFilter({ index: key, val: !item.isActive })"
            inset
            :ripple="false"
            color="accent"
            style="margin-left: 15px"
          ></v-switch>
          <img :src="item.pin" alt="Icon" class="icon" />
        </div>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: 'BottomSheet',
  props: {
    sheet: {
      type: Boolean,
      required: true,
    },
    filterList: {
      type: Array,
      required: true,
    },
    onApplyFilter: {
      type: Function,
      required: true,
    },
  },
};
</script>
