<template>
  <v-dialog
    v-model="showGiftDialog"
    transition="dialog-bottom-transition"
    content-class="gift-dialog"
    persistent
    :retain-focus="false"
  >
    <div class="modal">
      <div class="gift-card">
        <div class="card-content">
          <h3 style="margin-bottom: 20px" :style="{ 'font-size': gotRewarded ? '11px' : '13px' }">
            {{ headerText }}
          </h3>
          <p class="infomodal-info-text">
            {{ bodyText }}
          </p>
        </div>
        <div class="flex places-count">
          <img id="info-card-logo" :src="gotRewarded ? completedBox : infoBox" alt="Icon" />
          <p class="info-card-place-name">{{ placeVisitedCount }}/{{ maximumLimit }}</p>
        </div>
        <h3>Visits</h3>
      </div>
      <div class="card-action-buttons gift-buttons flex">
        <v-btn v-if="gotRewarded" class="card-btn" @click="openGiftLink">Enter</v-btn>
        <v-btn class="card-btn" @click="toggleGiftDiaog" color="primary">Close</v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import infoBox from '@/assets/bottomBar/voucher_frame.png';
import completedBox from '@/assets/bottomBar/Voucher_Complete.png';
import { mapState } from 'vuex';
export default {
  name: 'GiftDialog',
  data() {
    return {
      infoBox,
      completedBox,
    };
  },
  methods: {
    openGiftLink() {
      this.darabaseAPI.track('external-link', 'voucherSite');
      window.open('https://www.belgravialdn.com/bib-competition/');
    },
  },
  computed: {
    ...mapState({
      darabaseAPI: (state) => state.darabaseAPI,
      placeVisitedCount: (state) => state.placeVisitedCount,
    }),
    gotRewarded() {
      return this.placeVisitedCount == this.maximumLimit;
    },
    headerText() {
      // voucher dialog header text based on if rewarded
      return this.gotRewarded
        ? 'Thank you for exploring Belgravia in Bloom'
        : 'Discover Belgravia and be rewarded.';
    },
    bodyText() {
      // voucher dialog body text based on if rewarded or out of radius

      if (this.locationOutOfRadius) {
        return `You are currently accessing this content outside Belgravia. Please visit Belgravia in Bloom between 20-26th September 2021. Take a tour throughout Belgravia's most beautiful blooms to unlock a voucher and for the chance to win £1000.`;
      } else {
        if (this.gotRewarded) {
          return `You've now unlocked the chance to win £1000 to spend in Belgravia! Simply click the link below to enter our prize draw.`;
        } else {
          return `Take a tour throughout Belgravia's most beautiful blooms and unlock a voucher! Visit three of our locations and scan the QR codes for a chance to win £1000`;
        }
      }
    },
  },
  props: {
    showGiftDialog: {
      type: Boolean,
      required: true,
    },
    maximumLimit: {
      type: Number,
      required: true,
    },
    locationOutOfRadius: {
      type: Boolean,
      required: true,
    },
    toggleGiftDiaog: {
      type: Function,
      required: true,
    },
  },
};
</script>
