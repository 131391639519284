import Vue from 'vue';
import Vuex from 'vuex';
import { DarabaseApi } from '@darabase/darabase-websdk';

Vue.use(Vuex);
let thisUrl = window.location.pathname;
if (thisUrl.substr(-2) === '#/') thisUrl = thisUrl.substr(0, thisUrl.length - 2);

const urlParts = thisUrl.split('/');
const campaignKey = urlParts[1] || 'bib2021';

let apiConf = {
  endpoint: process.env.VUE_APP_DARABASE_ENDPOINT,
  key: process.env.VUE_APP_DARABASE_KEY,
  campaign: campaignKey,
};

export default new Vuex.Store({
  state: {
    // global states used in app
    map: null, // map is assigned for this
    bottomFilterSheet: false,
    infoButtonTapCount: 0,
    darabaseAPI: null, // API is assigned
    mapData: null, //
    campaignData: null,
    showIntroSlider: false,
    isFromHome: false,
    isGiftBox: false,
    launchScreenLoaded: localStorage.getItem('launchScreenLoaded') || false,
    showErrorDialog: false,
    errorMessage: {},
    placesVisited: JSON.parse(localStorage.getItem('placesVisited') || '{}') || {},
    placeVisitedCount:
      Object.keys(JSON.parse(localStorage.getItem('placesVisited') || '{}')).length || 0,
  },
  mutations: {
    SET_MAP(state, payload) {
      state.map = payload.map;
    },
    INCREMENT_PLACE_VISITED(state, payload) {
      // add true for based on UUID to avoid unnecessary increment
      const { id, visited } = payload;
      state.placesVisited[id] = visited;
      state.placeVisitedCount = Object.keys(state.placesVisited).length;
      localStorage.setItem('placesVisited', JSON.stringify(state.placesVisited));
    },
    SET_IS_FROM_HOME(state, payload) {
      state.isFromHome = payload;
    },
    // load launch screen only once
    SET_LAUNCH_SCREEN_LOADED(state, payload) {
      localStorage.setItem('launchScreenLoaded', 'true');
      state.launchScreenLoaded = payload;
    },
    SET_SHOW_INTRO_SLIDER(state, payload) {
      state.showIntroSlider = payload;
    },
    SET_CAMPAIGN_DATA(state, payload) {
      const { _daid, campaign, _sid } = payload;
      document.cookie = '_daid=' + _daid;
      document.cookie = '_sid=' + _sid;
      state.campaignData = campaign;
    },
    UPDATE_CAMPAIGN_DATA(state, payload) {
      state.campaignData['permissions'] = payload.permissions;
    },
    // assign map POI to map data
    SET_MAP_DATA(state, payload) {
      const { points } = payload;
      state.mapData = points;
    },
    SET_DARABASE_API(state, payload) {
      state.darabaseAPI = payload.darabaseAPI;
    },
    SET_INFO_BUTTON_TAP_COUNT(state, payload) {
      state.infoButtonTapCount = payload.tap;
    },
    TOGGLE_BOTTOM_FILTER_SHEET(state) {
      state.bottomFilterSheet = !state.bottomFilterSheet;
    },
    TOGGLE_SHOW_ERROR_DIALOG(state) {
      state.showErrorDialog = !state.showErrorDialog;
      // if geo locate fails it reloads
      if (state.errorMessage.buttonText == 'continue') {
        location.reload();
      }
    },
    SET_ERROR_MESSAGE(state, payload) {
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          const element = payload[key];
          state.errorMessage[key] = element;
        }
      }
    },
  },
  // all async calls should be made here
  actions: {
    // initializing darabase API
    async initDarabaseAPI({ commit }) {
      try {
        let api = new DarabaseApi();
        commit('SET_DARABASE_API', { darabaseAPI: api });
        if (api) {
          const response = await api.initialise(apiConf);
          if (response && response.data) {
            commit('SET_CAMPAIGN_DATA', response.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // initializing map POIs
    async getInitialMapData({ commit }) {
      try {
        if (this.state.campaignData && this.state.campaignData.urlKey) {
          const response = await this.state.darabaseAPI.get(
            '/client/pointdata/' + this.state.campaignData.urlKey
          );
          if (response && response.data) {
            commit('SET_MAP_DATA', response.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {},
});
