<template>
  <div class="home">
    <ErrorDialog
      :errorMessage="errorMessage"
      :showErrorDialog="showErrorDialog"
      :toggleErrorDialog="toggleErrorDialog"
    />
    <WelcomeDialog
      :showWelcomeDialog="showWelcomeDialog"
      :closeWelcomeDialog="closeWelcomeDialog"
    />
    <!-- voucher modal -->
    <GiftDialog
      :showGiftDialog="showGiftDialog"
      :maximumLimit="maximumLimit"
      :toggleGiftDiaog="toggleGiftDiaog"
      :locationOutOfRadius="locationOutOfRadius"
    />
    <!-- info modal -->
    <InfoDialog
      :disableAr="disableAr"
      :showInfoDialog="showInfoDialog"
      :infoObj="destinationInfo"
      :closeInfoDialog="closeInfoDialog"
      :isHero="isHero"
      :locationOutOfRadius="locationOutOfRadius"
    />
    <div v-if="fetchingMapData == 'in_progress'">
      <Loader loadingText="Fetching map data" :loadingStatus="fetchingMapData == 'in_progress'" />
    </div>
    <!-- tutorial model -->
    <IntroSlider
      v-if="showIntroSlider"
      @introSlideHandler="closeTurotial"
      @introSlideBack="introSlideBack"
      :style="{ width: `${windowWidth}px`, height: `${windowHeight - 50}px` }"
      class="intro-slider"
    />

    <div ref="myMap" class="my-map"></div>
    <!-- filter sheet -->
    <BottomSheet
      :sheet="bottomFilterSheet"
      :filterList="filterList"
      :onApplyFilter="
        (val) => {
          onApplyFilter(val);
        }
      "
    />
    <!-- bottom info bar -->
    <InfoCard
      :infoSheet="showInfoSheet"
      :placeObj="placeObj"
      :openInfoDialog="openIfoDialog"
      :key="sheetKey"
      :closeInfoSheet="() => (showInfoSheet = false)"
    />
    <!-- bottom bar with filter and voucher icon -->
    <BottomBar
      :bottomFilterSheet="bottomFilterSheet"
      :showInfoSheet="showInfoSheet"
      :bottomSheetHandler="bottomSheetHandler"
      :maximumLimit="maximumLimit"
      :windowHeight="windowHeight"
      :toggleGiftDiaog="toggleGiftDiaog"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import * as L from 'leaflet';
import currentUserPin from '@/assets/mapIcons/user_position.png';
import shoppingPin from '@/assets/mapIcons/bttn_purple_pin.png';
import diningPin from '@/assets/mapIcons/bttn_yellow_pin.png';
import hotelPin from '@/assets/mapIcons/bttn_blue_pin.png';
import beautyAndWellBeingPin from '@/assets/mapIcons/bttn_red_pin.png';
import shoppingIcon from '@/assets/filterIcons/icon_shopping.png';
import diningIcon from '@/assets/filterIcons/icon_dining.png';
import hotelIcon from '@/assets/filterIcons/icon_hotels.png';
import beautyAndWellBeingIcon from '@/assets/filterIcons/Iion_beauty&wellbeing.png';
import shoppingIconGray from '@/assets/filterIcons/icon_shopping_greyed.png';
import diningIconGray from '@/assets/filterIcons/icon_dining_greyed.png';
import hotelIconGray from '@/assets/filterIcons/icon_hotels_greyed.png';
import beautyAndWellBeingIconGray from '@/assets/filterIcons/icon_beauty&wellbeing_greyed.png';
import elizabethStreetIcon from '@/assets/mapIcons/bttn_eliz_map.png';
import motconbStreetIcon from '@/assets/mapIcons/bttn_motcomb_map.png';
import coxLondon from '@/assets/mapIcons/bttn_AR_Cox.png';
import pimlicoStreetIcon from '@/assets/mapIcons/bttn_pimlico_map.png';
import ecclestonYardIcon from '@/assets/mapIcons/bttn_eccl_map.png';
import noInternetImg from '@/assets/Icon_no_internet.png';
import floralImage from '@/assets/Icon_Floral_Fairground.png';
import belgraviaLogoError from '@/assets/belgravialdn-logo.jpeg';
import swal from 'sweetalert';
import Loader from '@/components/Loader.vue';
import IntroSlider from '@/components/IntroSlider.vue';
import BottomSheet from '@/components/filter/FilterOption.vue';
import InfoCard from '@/components/InfoCard.vue';
import BottomBar from '@/components/BottomBar.vue';
import InfoDialog from '@/components/infoDialog.vue';
import GiftDialog from '@/components/GiftCardDialog.vue';
import WelcomeDialog from '@/components/WelcomeDialog.vue';
import ErrorDialog from '@/components/ErrorDialog.vue';
import Carousel from '@/assets/mapIcons/bttn_carousel.png';
import Helterskelter from '@/assets/mapIcons/bttn_helterskelter.png';
import Chelsea from '@/assets/mapIcons/Chelsea-Icon.png';
import Chestnut from '@/assets/mapIcons/Chestnut_Icon.png';
import Errol from '@/assets/mapIcons/Errol_Icon.png';
import Judith from '@/assets/mapIcons/Judith_Icon.png';
import Lanesborough from '@/assets/mapIcons/Lanesborough_Icon.png';
import MasterPeace from '@/assets/mapIcons/MasterPeace_Icon.png';
import Moyses from '@/assets/mapIcons/Moyses_Icon.png';
import Neill from '@/assets/mapIcons/Neill_Icon.png';
import BelgraviaStandard from '@/assets/mapIcons/Belgravia-Standard.png';
import { mapActions, mapMutations, mapState } from 'vuex';
import { DarabaseUI } from '@darabase/darabase-websdk';

export default {
  name: 'Home',
  data() {
    return {
      currentUserPin,
      shoppingPin,
      beautyAndWellBeingPin,
      diningPin,
      hotelPin,
      shoppingIcon,
      diningIcon,
      hotelIcon,
      Carousel,
      Helterskelter,
      beautyAndWellBeingIcon,
      shoppingIconGray,
      diningIconGray,
      hotelIconGray,
      noInternetImg,
      floralImage,
      Chestnut,
      Chelsea,
      coxLondon,
      beautyAndWellBeingIconGray,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      currentLocation: {},
      currentLocationMarker: null,
      destinationMarker: null,
      layoutTwoLayer: null,
      layoutOneLayer: null,
      fetchingMapData: '',
      marker: null,
      filteredLocation: [],
      destinationInfo: {},
      placeObj: {},
      sheetKey: 0,
      currentRoute: null,
      maximumLimit: 3,
      showInfoSheet: false,
      showInfoDialog: false,
      showGiftDialog: false,
      isHero: false,
      locationOutOfRadiusErrorCount: 0,
      ismobileBrowserErrorCount: 0,
      locationAccessErrorCount: 0,
      canTestAr: false,
      centreCoordinate: [51.49607848430074, -0.15244915530408693], //center POI for the map
      showWelcomeDialog: localStorage.getItem('showIntro') == 'false' ? false : true,
      url: `https://api.mapbox.com/styles/v1/${process.env.VUE_APP_MAP_BOX_USER_NAME}/${process.env.VUE_APP_MAP_BOX_STYLE_ID}/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAP_BOX_TOKEN}`,
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      currentRouteSummery: {},
      streetArray: [],
      filterList: [
        {
          type: 'Shopping',
          icon: shoppingIcon,
          grayIcon: shoppingIconGray,
          pin: shoppingPin,
          isActive: true,
        },
        {
          type: 'Dining',
          icon: diningIcon,
          grayIcon: diningIconGray,
          pin: diningPin,
          isActive: true,
        },
        { type: 'Hotel', icon: hotelIcon, grayIcon: hotelIconGray, pin: hotelPin, isActive: true },
        {
          type: 'Beauty & Wellbeing',
          icon: beautyAndWellBeingIcon,
          grayIcon: beautyAndWellBeingIconGray,
          pin: beautyAndWellBeingPin,
          isActive: true,
        },
      ],
      streetLocationArray: [
        {
          name: 'elizabeth street',
          latLng: [51.49412395681741, -0.15178252957245084],
          street: 'eliz',
          icon: elizabethStreetIcon,
        },
        {
          name: 'Motconb street',
          latLng: [51.499511, -0.156639],
          street: 'motc',
          icon: motconbStreetIcon,
        },
        {
          name: 'pimlico street',
          latLng: [51.49048529325474, -0.1534338743119048],
          street: 'piml',
          icon: pimlicoStreetIcon,
        },
        {
          name: 'eccleston yard',
          latLng: [51.4936, -0.1483],
          street: 'eccl',
          icon: ecclestonYardIcon,
        },
      ],
    };
  },
  components: {
    Loader,
    IntroSlider,
    BottomSheet,
    InfoCard,
    InfoDialog,
    BottomBar,
    GiftDialog,
    WelcomeDialog,
    ErrorDialog,
  },
  mounted() {
    this.initDarabaseAPI();
    this.setUpMap();
    this.setMarkerLayer();
    this.updateMaWithIncomingLocations();
    localStorage.setItem('locationAccess', 'checking');
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    });
    window.addEventListener('offline', () => {
      this.TOGGLE_SHOW_ERROR_DIALOG();
      this.SET_ERROR_MESSAGE({
        headerText: 'No internet access',
        bodyText: 'Please connect to your internet to view the content.',
        image: noInternetImg,
        buttonText: 'Tap to close',
        bodyText1: '',
      });
    });
  },
  watch: {
    // if the bowser is opened from desktop it will throw the error
    ismobileBrowser: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val && this.ismobileBrowserErrorCount < 1) {
          console.log('!mobile browser');
          this.SET_ERROR_MESSAGE({
            headerText: 'Site open in Desktop Browser',
            bodyText: 'This site is optimised for mobile only.',
            bodyText1: '',
            image: belgraviaLogoError,
            buttonText: 'Tap to close',
          });
          this.ismobileBrowserErrorCount += 1;
          this.TOGGLE_SHOW_ERROR_DIALOG();
          // setting timeout as we need to show the out of radius error as well
          setTimeout(() => {
            this.TOGGLE_SHOW_ERROR_DIALOG();
          }, 3000);
        }
      },
    },
    // automatically open the voucher modal on getting voucher
    placeVisitedCount(val) {
      console.log(val);
    },
    // check the crowfly distance if it less  then equal to 25 m then remove the current route also you will be able to test AR
    crowFlyDistanceTOPoi(val) {
      if (val <= 25) {
        this.canTestAr = true;
        if (this.currentRoute != null) {
          this.map.removeControl(this.currentRoute);
        }
      }
    },
    showIntroSlider(val) {
      if (val) {
        this.showGiftDialog = false;
        this.showInfoDialog = false;
        if (this.showErrorDialog) {
          this.TOGGLE_SHOW_ERROR_DIALOG();
        }
        this.showInfoSheet = false;
        if (this.bottomFilterSheet) {
          this.TOGGLE_BOTTOM_FILTER_SHEET();
        }
      }
    },
    errorMessage: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log(val);
      },
    },
    locationOutOfRadius(val) {
      if (val && this.locationOutOfRadiusErrorCount < 1) {
        this.SET_ERROR_MESSAGE({
          headerText: 'Come visit Belgravia',
          bodyText:
            'You are currently accessing this content outside of Belgravia, this means you will be given limited access to the content available.',
          bodyText1:
            '',
          image: belgraviaLogoError,
          buttonText: 'Tap to close',
        });
        if (!this.showIntroSlider && !this.showWelcomeDialog && !this.showErrorDialog) {
          this.locationOutOfRadiusErrorCount += 1;
          if (val == true) {
            this.TOGGLE_SHOW_ERROR_DIALOG();
          }
        }
      }
    },
    // on getting campaign data setup the permission screen
    campaignData(val) {
      if (val) {
        if (!this.launchScreenLoaded) {
          this.setupPermissionScreen();
        }
        this.getInitialMapData();
      }
    },
    // watch current lat lng
    currentLatlng: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val && this.locationAccessErrorCount < 1 && this.locationAccessDenied) {
          this.SET_ERROR_MESSAGE({
            headerText: 'No permissions granted',
            bodyText:
              'Please enable permissions from your settings for the best experience using this application.',
            bodyText1:
              '',
            image: belgraviaLogoError,
            buttonText: 'Tap to close',
          });
          if (!this.showIntroSlider && !this.showWelcomeDialog) {
            this.TOGGLE_SHOW_ERROR_DIALOG();

            this.locationAccessErrorCount += 1;
          }
        } else {
          if (!this.locationOutOfRadius) {
            this.setUpCurrentLocationMarker(val);
          }
        }
      },
    },
    locationsArray: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val != null) {
          this.filteredLocation = val;
        }
      },
    },
    // watch filter location if there is any chnages
    filteredLocation: {
      immediate: true,
      handler(val) {
        if (val != null) {
          if (this.layoutOneLayer == null || this.layoutTwoLayer == null) {
            this.setMarkerLayer();
          }
          if (this.layoutTwoLayer != null) {
            this.layoutTwoLayer.clearLayers();
          }
          this.updateMaWithIncomingLocations();
        }
      },
    },
    // change the layers based on zoom level of the map
    zoomLevel: {
      handler(newVal, oldVal) {
        if (newVal != null && newVal != oldVal && this.map != null) {
          if (this.layoutOneLayer == null || this.layoutTwoLayer == null) {
            this.setMarkerLayer();
          }
          if (newVal > 15) {
            this.map.removeLayer(this.layoutOneLayer);
            this.map.addLayer(this.layoutTwoLayer);
          } else {
            this.map.removeLayer(this.layoutTwoLayer);
            this.map.addLayer(this.layoutOneLayer);
          }
        }
      },
    },
    // it will change as user moves (crowfly distance)
    distanceFromCentrePoint: {
      handler(newVal, oldVal) {
        if (
          newVal != null &&
          newVal != oldVal &&
          this.map != null &&
          this.currentLocation != null
        ) {
          if (this.currentLocationMarker == null) {
            const { lat, lng } = this.currentLocation;
            this.setUpCurrentLocationMarker([lat, lng]);
          }

          if (newVal <= process.env.VUE_APP_GEO_FENCE_RADIUS) {
            this.map.addLayer(this.currentLocationMarker);
          } else if (newVal == process.env.VUE_APP_GEO_FENCE_RADIUS + 1) {
            if (this.currentRoute != null) {
              this.map.removeControl(this.currentRoute);
            }
            this.map.removeLayer(this.currentLocationMarker);
            this.SET_ERROR_MESSAGE({
              headerText: 'You are now leaving Belgravia in Bloom',
              bodyText:
                'This means you will be given limited access to the content available.',
              bodyText1:
                '',
              image: belgraviaLogoError,
              buttonText: 'Tap to close',
            });
            if (!this.showIntroSlider && !this.showWelcomeDialog) {
              this.TOGGLE_SHOW_ERROR_DIALOG();
            }
          } else {
            if (this.currentRoute != null) {
              this.map.removeControl(this.currentRoute);
            }
            this.map.removeLayer(this.currentLocationMarker);
          }
        }
      },
    },
  },
  computed: {
    ...mapState({
      map: (state) => state.map,
      bottomFilterSheet: (state) => state.bottomFilterSheet,
      infoButtonTapCount: (state) => state.infoButtonTapCount,
      darabaseAPI: (state) => state.darabaseAPI,
      campaignData: (state) => state.campaignData,
      mapData: (state) => state.mapData,
      showIntroSlider: (state) => state.showIntroSlider,
      launchScreenLoaded: (state) => state.launchScreenLoaded,
      showErrorDialog: (state) => state.showErrorDialog,
      errorMessage: (state) => state.errorMessage,
      placeVisitedCount: (state) => state.placeVisitedCount,
    }),
    ismobileBrowser() {
      /* eslint-disable */
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);

      return check;
    },
    locationsArray() {
      if (this.mapData) {
        return this.mapData.map((location) => {
          if (location.icon != null && location.cat && location.enabled === 1) {
            if (location.icon == 'dining') {
              location['pin'] = diningPin;
            }
            if (location.icon == 'shopping') {
              location['pin'] = shoppingPin;
            }
            if (location.icon == 'beauty & wellbeing') {
              location['pin'] = beautyAndWellBeingPin;
            }
            if (location.icon == 'hotel') {
              location['pin'] = hotelPin;
            }
            if (location.icon == 'carousel') {
              location['pin'] = Carousel;
            }
            if (location.icon == 'helterskelter') {
              location['pin'] = Helterskelter;
            }
            if (location.icon == 'chelsea') {
              location['pin'] = Chelsea;
            }
            if (location.icon == 'chestnut') {
              location['pin'] = Chestnut;
            }
            if (location.icon == 'errol') {
              location['pin'] = Errol;
            }
            if (location.icon == 'judith') {
              location['pin'] = Judith;
            }
            if (location.icon == 'masterpeace') {
              location['pin'] = MasterPeace;
            }
            if (location.icon == 'moyses') {
              location['pin'] = Moyses;
            }
            if (location.icon == 'neill') {
              location['pin'] = Neill;
            }
            if (location.icon == 'lanesborough') {
              location['pin'] = Lanesborough;
            }
            if (location.icon == 'coxLondon') {
              location['pin'] = coxLondon;
            }
            if (location.icon == 'belgraviaIcon') {
              location['pin'] = BelgraviaStandard;
            }
            return location;
          }
        });
      }
      return null;
    },
    zoomLevel() {
      if (this.map == null) {
        this.setUpMap();
      }
      return this.map ? this.map._zoom : null;
    },
    locationAccessDenied() {
      return localStorage.getItem('locationAccess') == 'denied';
    },
    currentLatlng() {
      return this.currentLocation
        ? L.latLng(this.currentLocation.lat, this.currentLocation.lng)
        : '';
    },
    distanceFromCentrePoint() {
      if (this.map == null) {
        this.setUpMap();
      }
      if (
        this.map != null &&
        this.currentLocation &&
        this.currentLocation.lat &&
        this.currentLocation.lng
      ) {
        return this.map.distance(
          L.latLng(this.centreCoordinate[0], this.centreCoordinate[1]),
          L.latLng(this.currentLocation.lat, this.currentLocation.lng)
        );
      } else {
        return null;
      }
    },
    locationOutOfRadius() {
      return this.distanceFromCentrePoint > process.env.VUE_APP_GEO_FENCE_RADIUS;
    },
    crowFlyDistanceTOPoi() {
      if (this.map == null) {
        this.setUpMap();
      }
      if (
        this.map != null &&
        this.currentLocation &&
        this.currentLocation.lat &&
        this.currentLocation.lng &&
        this.destinationInfo &&
        this.destinationInfo.lat &&
        this.destinationInfo.lng
      ) {
        return this.map.distance(
          L.latLng(this.destinationInfo.lat, this.destinationInfo.lng),
          L.latLng(this.currentLocation.lat, this.currentLocation.lng)
        );
      } else {
        return null;
      }
    },
    disableAr() {
      return this.locationOutOfRadius || this.locationAccessDenied || !this.canTestAr;
      //return false;
    },
    // check default way of watching distance as this is not working
    // totalDistanceOnrouteFound() {
    //   let distance = '';
    //   if (this.currentRoute) {
    //     this.currentRoute.on('routesfound', (e) => {
    //       this.SET_INFO_BUTTON_TAP_COUNT({ tap: 0 });
    //       this.showInfoSheet = true;
    //       var { totalDistance } = e.routes[0].summary;
    //       distance = `${totalDistance}m`;
    //       this.placeObj.distance = distance;
    //     });
    //   }
    //   return distance;
    // },
  },

  methods: {
    ...mapMutations([
      'SET_MAP',
      'TOGGLE_BOTTOM_FILTER_SHEET',
      'SET_INFO_BUTTON_TAP_COUNT',
      'UPDATE_CAMPAIGN_DATA',
      'SET_SHOW_INTRO_SLIDER',
      'SET_LAUNCH_SCREEN_LOADED',
      'TOGGLE_SHOW_ERROR_DIALOG',
      'SET_ERROR_MESSAGE',
    ]),
    ...mapActions(['initDarabaseAPI', 'getInitialMapData']),
    // toggle filter sheet
    bottomSheetHandler() {
      this.TOGGLE_BOTTOM_FILTER_SHEET();
      if (
        Object.keys(this.placeObj).length &&
        this.showInfoDialog == false &&
        this.bottomFilterSheet == false &&
        this.infoButtonTapCount == 0
      ) {
        this.showInfoSheet = true;
      } else {
        this.showInfoSheet = false;
      }
    },
    setupPermissionScreen() {
      this.SET_LAUNCH_SCREEN_LOADED(true);
      new DarabaseUI().showPermissionsUI(this.campaignData, (opts) => {
        this.UPDATE_CAMPAIGN_DATA({ permissions: opts.permissions });

        this.darabaseAPI.track('camera-perms', opts.permissions.camera);
        this.darabaseAPI.track('sensor-perms', opts.permissions.sensor);

        if (opts.termsRead) this.darabaseAPI.track('user', 'terms-link');

        if (opts.privacyRead) this.darabaseAPI.track('user', 'privacy-link');
      });
    },
    convertTimeTo(time) {
      let day, hour, min;
      if (time) {
        day = Math.floor(time / 86400);
        time %= 86400;
        hour = Math.floor(time / 3600);
        time %= 3600;
        min = (time / 60).toFixed(1);
        return { day, hour, min };
      }
      return {};
    },
    // when street layer marker is clicked(layout one)
    streetMarkerClicked(e) {
      this.map.flyTo(e.latlng, 17);
    },
    // when POI is clicked
    markerClicked(e) {
      this.destinationInfo = e.layer.destination;
      this.destinationMarker = e.latlng;
      this.sheetKey += 1;
      this.placeObj.name = this.destinationInfo.name;
      this.placeObj.uuid = this.destinationInfo.uuid;
      this.placeObj.description = this.destinationInfo.description;
      this.placeObj.imageLogo = this.destinationInfo.image;
      this.isHero = this.destinationInfo.cat == 'hero' || this.destinationInfo.cat == 'video';

      if (this.currentLatlng && !(this.locationAccessDenied || this.locationOutOfRadius)) {
        this.fetchingMapData = 'in_progress';
        this.getDirection();
      } else {
        this.openIfoDialog();
      }

      // get route
      // get the info pannel
    },
    // add poi to the map
    updateMaWithIncomingLocations() {
      if (this.filteredLocation.length != 0 && this.layoutTwoLayer != null) {
        for (const location of this.filteredLocation) {
          if (location && location.cat && location.pin) {
            this.marker = L.marker([location.lat, location.lng], {
              icon: L.icon({
                iconUrl: location.pin,
                iconSize:
                  location.cat == 'video'
                    ? [75, 75]
                    : location.cat === 'hero'
                    ? [150, 150]
                    : [42, 50], // size of the icon
              }),
            }).addTo(this.layoutTwoLayer);
            this.marker.destination = location;
          }
        }
      }

      if (this.streetLocationArray != null && this.layoutOneLayer != null) {
        for (const streetLocation of this.streetLocationArray) {
          const layerOneMarker = L.marker(streetLocation.latLng, {
            icon: L.icon({
              iconUrl: streetLocation.icon,
              iconSize: [95, 95], // size of the icon
            }),
          }).addTo(this.layoutOneLayer);
          layerOneMarker.destination = streetLocation;
        }
      }
    },
    // set two marker layer
    setMarkerLayer() {
      if (this.map != null) {
        this.layoutTwoLayer = L.featureGroup().on('click', this.markerClicked);
        this.layoutOneLayer = L.featureGroup().on('click', this.streetMarkerClicked);
      } else {
        this.setUpMap();
      }
    },
    // initilise map
    setUpMap() {
      // get the map div refference
      const _map = this.$refs.myMap;
      // assign to map
      if (_map != null) {
        this.SET_MAP({
          map: L.map(_map, { maxZoom: 20, minZoom: 1 }).setView(this.centreCoordinate, 15),
        });
        // add title layer
        L.tileLayer(this.url, {
          attribution: this.attribution,
          maxZoom: 21,
          id: 'mapbox/streets-v11',
          tileSize: 512,
          zoomOffset: -1,
        }).addTo(this.map);
        this.updateMapWithCurrentLocation();
      }
    },
    // set current location marker
    setUpCurrentLocationMarker(latlng) {
      if (this.currentLocationMarker != null) {
        this.map.removeLayer(this.currentLocationMarker);
      }
      this.currentLocationMarker = L.marker(latlng, {
        draggable: false,
        icon: L.icon({
          iconUrl: currentUserPin,
          iconSize: [30, 30], // size of the icon
          iconAnchor: [15, 28], // point of the icon which will correspond to marker's location
          popupAnchor: [0, -28], // point of the icon which will correspond to marker's location
        }),
      }).bindPopup('Your are here :)');
    },
    // get current location
    updateMapWithCurrentLocation() {
      if (this.map != null) {
        this.map
          .locate({
            watch: true,
            maxZoom: 13,
            enableHighAccuracy: true,
          })
          .on('locationfound', (e) => {
            localStorage.setItem('locationAccess', 'enabled');
            this.currentLocation = { lat: e.latitude, lng: e.longitude };
          })
          .on('locationerror', function (e) {
            this.currentLocation = {};
            if (!(e.message.includes('Timeout') || e.message.includes('Network'))) {
              localStorage.setItem('locationAccess', 'denied');
            } else {
              this.TOGGLE_SHOW_ERROR_DIALOG();
              this.SET_ERROR_MESSAGE({
                headerText: 'Location error',
                bodyText: 'Your location can not be found.',
                bodyText1: '',
                image: belgraviaLogoError,
                buttonText: 'continue',
              });
            }
          });
      }
    },
    // get the direction when poi is clicked if inarea
    getDirection() {
      // remove current direction
      if (this.currentRoute != null) {
        this.map.removeControl(this.currentRoute);
      }
      // get direction and add if current location is there
      if (Object.keys(this.currentLocation).length) {
        let mapboxRouting = L.Routing.mapbox(process.env.VUE_APP_MAP_BOX_TOKEN, {
          profile: 'mapbox/walking',
        });
        this.currentRoute = L.Routing.control({
          router: mapboxRouting,
          waypoints: [
            this.currentLatlng,
            L.latLng(this.destinationMarker.lat, this.destinationMarker.lng),
          ],
          routeWhileDragging: true,
          autoRoute: true,
          showAlternatives: false,
          createMarker: function () {
            return null;
          },
          lineOptions: {
            addWaypoints: false,
            styles: [{ color: '#f44748', opacity: 1, weight: 5, dashArray: '5,10' }],
          },
        });
        this.currentRoute
          .on('routesfound', (e) => {
            this.SET_INFO_BUTTON_TAP_COUNT({ tap: 0 });
            this.showInfoSheet = true;
            this.fetchingMapData = 'completed';
            var { totalDistance } = e.routes[0].summary;
            let distance = `${Math.round(totalDistance, 0)}m`;
            if (totalDistance > 1000) {
              distance = `${Math.round(totalDistance / 1000, 1)}Km`;
            }
            this.placeObj.distance = distance;
          })
          .on('routingerror', (e) => {
            this.fetchingMapData = 'failed';
            swal('Sorry', 'routing error please refresh the page', 'error');
            console.log(e);
          });

        this.currentRoute.onAdd(this.map);
      }
    },
    // toggle tutorial modal
    closeTurotial() {
      this.SET_SHOW_INTRO_SLIDER(false);
      if (this.errorMessage.headerText) {
        if (this.locationAccessErrorCount == 0 && this.locationAccessDenied)
          this.TOGGLE_SHOW_ERROR_DIALOG();
        this.locationAccessErrorCount += 1;
      }
      if (this.locationOutOfRadiusErrorCount == 0 && this.locationOutOfRadius) {
        this.TOGGLE_SHOW_ERROR_DIALOG();
        this.locationOutOfRadiusErrorCount += 1;
      }
    },
    // open welcome dialog
    introSlideBack() {
      this.SET_SHOW_INTRO_SLIDER(false);
      this.showWelcomeDialog = true;
      localStorage.setItem('showIntro', true);
    },
    // filter changes
    // add or remove POI from map based on applied filter
    onApplyFilter(changes) {
      const { index, val } = changes;
      this.filterList[index].isActive = val;
      const currentFilter = this.filterList[index].type.toLowerCase();
      if (!val) {
        const list = this.filteredLocation.filter((_location) => {
          if (_location && _location.subcat) {
            return _location.subcat.toString().toLowerCase() != currentFilter;
          }
        });
        this.filteredLocation = list;
      } else {
        const list = this.locationsArray.filter((_location) => {
          if (_location && _location.subcat) {
            return _location.subcat.toLowerCase() == currentFilter;
          }
        });
        this.filteredLocation.push(...list);
      }
      if (this.destinationMarker != null && this.currentRoute) {
        // handle if route is present
        const filteredLocationPresent = this.filteredLocation.find(
          (e) =>
            e.latLng[0] == this.destinationMarker.lat && e.latLng[1] == this.destinationMarker.lng
        );
        if (!filteredLocationPresent) {
          if (this.currentRoute != null) {
            this.showInfoSheet = false;
            this.placeObj = {};
            this.map.removeControl(this.currentRoute);
          }
        }
      }
    },
    // open info modal
    openIfoDialog() {
      this.SET_INFO_BUTTON_TAP_COUNT({ tap: 1 });
      this.showInfoSheet = false;
      this.showInfoDialog = true;
    },
    // close info modal
    closeInfoDialog() {
      this.showInfoDialog = false;
    },
    // toggle voucher modal
    toggleGiftDiaog() {
      this.showGiftDialog = !this.showGiftDialog;
      if (this.showGiftDialog) {
        this.showInfoDialog = false;
        if (this.showErrorDialog) {
          this.TOGGLE_SHOW_ERROR_DIALOG();
        }
        this.showInfoSheet = false;
        this.SET_SHOW_INTRO_SLIDER(false);
        this.showWelcomeDialog = false;
        if (this.bottomFilterSheet) {
          this.TOGGLE_BOTTOM_FILTER_SHEET();
        }
      }
    },
    // toggle error modal
    toggleErrorDialog() {
      this.TOGGLE_SHOW_ERROR_DIALOG();
      if (this.errorMessage.bodyText.includes('vote')) {
        if (this.destinationInfo) {
          this.showInfoDialog = true;
        }
      }
    },
    // close welcome modal
    closeWelcomeDialog() {
      this.showWelcomeDialog = false;
      localStorage.setItem('showIntro', false);
      this.SET_SHOW_INTRO_SLIDER(true);
    },
  },
};
</script>
<style>
.splash-content {
  width: 100%;
  top: 40% !important;
  position: relative;
}
.campaign-logo {
  width: 87vw;
}
.campaign-logo-container::after {
  display: none;
}
</style>
