<template>
  <v-dialog v-model="showErrorDialog" content-class="gift-dialog" persistent>
    <div class="modal">
      <div class="error-card">
        <div class="card-content">
          <h3 style="margin-bottom: 20px; font-size: 11px">
            {{ errorMessage.headerText }}
          </h3>
          <p class="infomodal-info-text">
            {{ errorMessage.bodyText }}
          </p>
          <p class="infomodal-info-text" v-if="errorMessage.bodyText1">
            {{ errorMessage.bodyText1 }}
          </p>
        </div>
        <div class="flex">
          <img id="info-card-logo" :src="errorMessage.image" style="width: 150px;" alt="Icon" />
        </div>
      </div>
      <div class="card-action-buttons">
        <v-btn @click="toggleErrorDialog" color="primary"
          >{{ errorMessage.buttonText }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'GiftDialog',
  props: {
    showErrorDialog: {
      type: Boolean,
      required: true,
    },
    toggleErrorDialog: {
      type: Function,
      required: true,
    },
    errorMessage: {
      type: Object,
      required: true,
    },
  },
};
</script>
