<template>
  <v-dialog
    v-model="showInfoDialog"
    transition="dialog-bottom-transition"
    :content-class="isHero ? 'info-dialog hero-dialog' : 'info-dialog'"
    persistent
    fullscreen
    :retain-focus="false"
  >
    <div class="video-player-wrapper" v-if="showVideoPlayer">
      <span @click="showVideoPlayer = false" class="close-video"
        ><v-icon color="white">mdi-close</v-icon></span
      >
      <video v-if="showVideoPlayer" controls class="video-player" controlsList="nodownload">
        <source :src="infoObj.placeholder" type="video/mp4" />
      </video>

      <v-card v-if="showVideoPlayer" class="out-of-belgravia-info">
        <v-card-text>
          For full experience in AR please use this app when visiting Belgravia in Bloom London
          between 20-26th September 2021.</v-card-text
        >
      </v-card>
    </div>

    <div v-else class="modal flex" id="infoModal">
      <div class="inner-infomodal-card" :class="isHero? 'info-hero-card':''" id="info-card-layer">
        <div class="flex content-wrapper">
          <div class="top-close-bar">
            <v-icon @click="closeInfoDialog">mdi-close</v-icon>
          </div>
          <div class="flex card-header">
            <img id="info-card-logo" :src="infoObj.image" alt="Icon" v-if="infoObj.image" />
          </div>
          <div class="card-content">
            <p class="infomodal-info-text">{{ infoObj.description }}</p>
          </div>
        </div>
        <div
          class="card-action-buttons flex"
          :style="{
            'padding-bottom': isHero ? '0px' : '15px',
            'align-items': 'center',
            'justify-content': 'flex-end',
          }"
        >
          <button class="card-btn active" @click="handleWebsiteClick">Website</button>

        </div>
        <div
          class="info-card-footer flex column"
          :style="{ display: infoObj.expurl ? 'flex' : 'none' }"
        >
          <p>Tap the button below to start AR</p>
          <div class="launch-ar-button">
            <button class="card-btn active" @click="handleArClick">Launch AR</button>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import floralImage from '@/assets/Icon_Floral_Fairground.png';
import fire from 'sweetalert';
import { getUUID } from './utils/utils';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { mapMutations, mapState } from 'vuex';
import GiftCardDialogVue from './GiftCardDialog.vue';
export default {
  name: 'InfoDialog',
  data() {
    return {
      showVideoPlayer: false,
    };
  },

  computed: {
    ...mapState({
      darabaseAPI: (state) => state.darabaseAPI,
      placeVisitedCount: (state) => state.placeVisitedCount,
    }),
  },
  methods: {
    ...mapMutations(['INCREMENT_PLACE_VISITED', 'TOGGLE_SHOW_ERROR_DIALOG', 'SET_ERROR_MESSAGE']),
    handleWebsiteClick() {
      this.darabaseAPI.track('external-link', 'WEBLINK-' + this.infoObj.uuid);
      window.open(this.infoObj.url);
    },
    handleArClick() {
      // if the disable ar prop is true which means its out of radius or location access not permitted
      if (this.disableAr) {
        this.showVideoPlayer = true;
      } else {
        //open web url
        this.darabaseAPI.track('external-link', 'ARHERO-' + this.infoObj.uuid).then((r) => {
          window.location.href = this.infoObj.expurl;
        });
      }
    },
    // note need to move this logic to store and this component should be dummy
    async locationVote(locationId) {
      let voteId;
      let freshVote = false;
      const inputTarget = event.target;
      inputTarget.innerHTML = 'Please Wait';
      inputTarget.disabled = true;
      if (this.locationOutOfRadius) {
        this.closeInfoDialog();
        this.TOGGLE_SHOW_ERROR_DIALOG();
        this.SET_ERROR_MESSAGE({
          headerText: 'We’re sorry',
          bodyText:
            'To vote on a floral display please visit Belgravia in Bloom London between the 20-26th of September 2021.',
          bodyText1: '',
          image: floralImage,
          buttonText: 'Tap to close',
        });
        inputTarget.innerHTML = 'Vote';
        inputTarget.disabled = false;
      } else {
        if (localStorage.getItem('voteId') !== null) {
          voteId = localStorage.getItem('voteId');
          let text = document.createElement('p');
          text.innerHTML =
            'You have voted for a location already. If you vote again, this will override your previous vote. <br> Press <b>OK</b> to confirm.';
          fire({
            closeOnClickOutside: false,
            content: text,
            buttons: {
              confirm: {
                text: 'OK',
                value: true,
                visible: true,
                className: '',
                closeModal: true,
              },
              cancel: {
                text: 'Cancel',
                value: false,
                visible: true,
                className: '',
                closeModal: true,
              },
            },
          }).then((result) => {
            if (!result) {
              inputTarget.innerHTML = 'Vote';
              inputTarget.disabled = false;
            } else {
              fire('Thank you for your vote!', '', 'success');
            }
          });
        } else {
          voteId = getUUID();
          localStorage.setItem('voteId', voteId);
          freshVote = true;
        }

        this.darabaseAPI.track('vote', locationId);
        console.log('Write to firestore incoming');

        try {
          const db = getFirestore();

          const docRef = await addDoc(collection(db, 'votes'), {
            voteIdentifier: voteId,
            voteDate: serverTimestamp(),
            voteChoice: locationId,
          });

          console.log('Written to Firestore');

          inputTarget.innerHTML = 'Vote';
          inputTarget.disabled = false;
          if (freshVote) {
            fire('Thank you for your vote!', '', 'success');
          }
        } catch (err) {
          alert('There was an error postng this vote');
          inputTarget.innerHTML = 'Vote';
          inputTarget.disabled = false;
        }
      }
    },
  },
  props: {
    showInfoDialog: {
      type: Boolean,
      required: true,
    },
    infoObj: {
      type: Object,
    },
    closeInfoDialog: {
      type: Function,
      required: true,
    },
    disableAr: {
      type: Boolean,
      required: true,
    },
    isHero: {
      type: Boolean,
      required: true,
    },
    locationOutOfRadius: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
