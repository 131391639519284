<template>
  <v-bottom-sheet v-model="infoSheet" persistent :retain-focus="true">
    <v-sheet class="text-center" height="134px" style="background: transparent">
      <div class="infoCard">
        <div class="half-circle"></div>
        <div class="card-layer"></div>
        <div class="card-inner-content">
          <div class="distance-card" ref="greenDistanceCard" v-if="placeObj.distance">
            <p ref="timeTakenCard">{{ placeObj.distance }}</p>
          </div>
          <div class="flex column place-card" v-if="placeObj.imageLogo">
            <img :src="placeObj.imageLogo" alt="Icon" class="icon place-logo" />
          </div>
          <p class="place-name">{{ placeObj.name }}</p>
          <v-btn :ripple="false" text outlined class="bottom-info-button" @click="openInfoDialog"
            >INFO</v-btn
          >
        </div>
        <div class="top-close-bar">
          <v-icon @click="closeInfoSheet">mdi-close</v-icon>
        </div>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: 'InfoCard',
  mounted() {
    if (this.$refs.timeTakenCard && this.infoSheet) {
      const witdth = this.$refs.timeTakenCard.clientWidth;
      this.$refs.greenDistanceCard.style.width = `${witdth + 100}px`;
    }
  },

  props: {
    infoSheet: {
      type: Boolean,
      required: true,
    },
    placeObj: {
      type: Object,
    },
    openInfoDialog: {
      type: Function,
      required: true,
    },
    closeInfoSheet: {
      type: Function,
      required: true,
    },
  },
};
</script>
