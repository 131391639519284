<template>
  <div>
    <v-stepper v-model="e1" class="slider">
      <span
        id="close-button"
        @click="
          () => {
            this.$emit('introSlideHandler');
          }
        "
        >x</span
      >
      <v-stepper-items>
        <v-stepper-content v-for="n in 4" :key="n" :step="n">
          <div class="card-header">
            <img v-if="n != 3" :src="headerImage[n - 1].image" alt="header image" />
            <div v-else>
              <img :src="headerImage[n - 1][0].imageOne" alt="" />
              <img :src="headerImage[n - 1][0].imageTwo" alt="" />
              <img :src="headerImage[n - 1][0].imageThree" alt="" />
            </div>
          </div>
          <div class="intro-card-content" :class="n == 4 ? 'reverse' : ''">
            <img :src="n < 3 ? bellOne : bellTwo" alt="bell image" />
            <p>{{ headerImage[n - 1].text }}</p>
            <p v-if="n == 3">{{ headerImage[n - 1][0].text }}</p>
          </div>
          <div class="stepper-counter">
            <div class="stepper-content" v-for="k in 4" :key="k">
              <v-stepper-step :step="k" :class="n == k ? 'active-counter' : ''" @click="e1 = k">
              </v-stepper-step>
              <span :class="k != 4 ? 'divider-dot' : ''"></span>
            </div>
          </div>
          <div class="button-wrapper">
            <v-btn color="primary" @click="introSlideBackHandler(n)"> Back </v-btn>
            <v-btn color="primary" @click="introSlideContinueHandler(n)"> Next </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import firstImage from '@/assets/tutorials/Tut_Map.png';
import secondImage from '@/assets/tutorials/Tut_TapMap.png';
import thirdImageOne from '@/assets/mapIcons/bttn_carousel.png';
import thirdImageTwo from '@/assets/mapIcons/bttn_AR_video.png';
import thirdImageThree from '@/assets/mapIcons/bttn_helterskelter.png';
import bellOne from '@/assets/tutorials/Bell_01.png';
import bellTwo from '@/assets/tutorials/Bell_02.png';
import fifthImage from '@/assets/tutorials/Belgravia_Logo_RGB_DarkGrey.png';
export default {
  name: 'IntroSlide',
  data() {
    return {
      e1: 1,
      bellOne,
      bellTwo,
      // content for the initial slider
      headerImage: [
        {
          image: firstImage,
          text: 'As well as finding your way around Belgravia, you can use this map to access augmented reality experiences around the neighbourhood.',
        },
        {
          image: secondImage,
          text: "Simply tap an icon on the map for additional information on each location and to get directions to each point.",
        },
        [
          {
            imageTwo: thirdImageTwo,
            text: 'If you see this AR icon, it means you can activate an immersive experience. When you are at that location, press the launch AR button to switch to your camera to enjoy the experience.',
          },
        ],
        { image: fifthImage, text: '' },
      ],
    };
  },
  methods: {
    introSlideContinueHandler(key) {
      if (key != 4) {
        this.e1 = key + 1;
      } else {
        // handling the closing of the slider at end
        this.$emit('introSlideHandler');
      }
    },
    introSlideBackHandler(key) {
      if (key != 1) {
        this.e1 = key - 1;
      } else {
        // opening Welcome dialog if back is pressed at first position
        this.$emit('introSlideBack');
      }
    },
  },
};
</script>
