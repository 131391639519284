<template>
  <div style="diplay: flex; flex-direction: column">
    <div class="top-nav">
      <div class="icon" @click="goTohome" v-if="isAboutPage">
        <v-icon color="white" left> mdi-arrow-left </v-icon>
      </div>
      <div class="icon" @click="showNav = !showNav" :class="showNav ? 'show-nav-bar' : ''" v-else>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </div>
      <div class="logo">
        <img :src="titleImage" alt="Title image" />
      </div>
    </div>
    <v-navigation-drawer v-model="showNav" class="nav-bar-temp">
      <v-list dense>
        <v-list-item @click="onclickMenu(1)">
          <v-list-item-content>
            <v-list-item-title>See our instagram filter</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <img :src="instaIcon" alt="insta Icon" style="object-fit: contain" />
          </v-list-item-icon>
        </v-list-item>
        <v-divider class="temp-bar"></v-divider>
        <v-list-item @click="onclickMenu(2)">
          <v-list-item-content>
            <v-list-item-title>Tutorial</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="temp-bar"></v-divider>
        <v-list-item @click="onclickMenu(3)">
          <v-list-item-content>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import instaIcon from '@/assets/topBar/logo_instagram.png';
import { mapMutations, mapState } from 'vuex';
export default {
  nane: 'TopNav',
  data() {
    return {
      showNav: false,
      instaIcon,
    };
  },
  methods: {
    ...mapMutations(['SET_IS_FROM_HOME']),
    goTohome() {
      // this.$router.go(-1);
      location.reload();
    },
    onclickMenu(menuItem) {
      // handle the nav menu click
      this.showNav = false;
      switch (menuItem) {
        case 1:
          this.darabaseAPI.track('instagram');
          window.open('https://www.instagram.com/ar/1054956821914123/');
          break;
        case 2:
          this.showTutorial();
          break;
        case 3:
          this.SET_IS_FROM_HOME(true);
          this.$router.push('/about');
          break;
        default:
          break;
      }
    },
  },
  computed: {
    ...mapState({
      darabaseAPI: (state) => state.darabaseAPI,
    }),
    isAboutPage() {
      return this.$route.name == 'About';
    },
  },
  props: {
    titleImage: {
      type: String,
      required: true,
    },
    showTutorial: {
      type: Function,
      required: true,
    },
  },
};
</script>
