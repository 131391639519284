<template>
  <v-col class="about-page">
    <img :src="belgraviaImage" alt="Belgravia in Bloom" />
    <p>
      Distinguished and unique, Belgravia is a delightful quintessential London neighbourhood. From artisan bakeries to brilliant boutiques, discover the wonders of Belgravia today.
    </p>
  </v-col>
</template>
<script>
import floralImage from '@/assets/bigLogoBelgravia.png';
import belgraviaImage from '@/assets/belgravia-london.png';

export default {
  name: 'About',
  data() {
    return {
      belgraviaImage,
    };
  },
};
</script>
