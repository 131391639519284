<template>
  <v-app>
    <!-- custom app bar so that it will be loaded in all the page -->
    <TopNav
      :titleImage="titleImage"
      :showTutorial="
        () => {
          this.SET_SHOW_INTRO_SLIDER(true);
        }
      "
    />
    <v-main>
      <v-card style="height: 100%; border-radious: 0px" class="overflow-hidden">
        <router-view />
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import titleImage from '@/assets/logo_belgravia.png';
import { mapMutations } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      titleImage,
    };
  },
  components: {
    TopNav,
  },
  methods: {
    ...mapMutations(['SET_SHOW_INTRO_SLIDER']),
  },
};
</script>
<style></style>
