import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.js';
import '@/assets/style/belgravia-theme-1.0.3.css';
import '@/style.scss';
import vuetify from '@/plugins/vuetify';
import { initializeApp } from "firebase/app";

//Temporary Firebase Usage
const firebaseApp = initializeApp({
  apiKey: "AIzaSyCJRMRRN473lbWX0AARGS-VoU6e1zRJbDA",
  authDomain: "belgravia-16d83.firebaseapp.com",
  projectId: "belgravia-16d83",
  storageBucket: "belgravia-16d83.appspot.com",
  messagingSenderId: "173833198294",
  appId: "1:173833198294:web:9a95e7bcee28ca7e3bc777"
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,

  render: function (h) {
    return h(App);
  },
}).$mount('#app');
