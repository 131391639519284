import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import store from '@/store';
Vue.use(VueRouter);
// routes for as there is only two for now
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    beforeEnter: isNotHome,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    // always making on top of the page on route change
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      window.scrollTo(0, 0);
    }
  },
});
function isNotHome(to, from, next) {
  // always redirect to home if url is entered
  if (!store.state.isFromHome) {
    next('/');
  }
  next();
}

export default router;
