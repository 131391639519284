<template>
  <v-dialog
    v-model="showWelcomeDialog"
    content-class="welcome-dialog"
    persistent
    :retain-focus="false"
  >
    <v-card active-class="welcome-card">
      <v-card-title>
        <img :src="titleImage" alt="Belgravia Image" />
      </v-card-title>
      <v-card-text class="flex">
        <img :src="bellOne" alt=" Bell one image" />
        <div class="welcome-texts">
          <p>Welcome to Belgravia in Bloom.</p>
          <p>
            This map will help you to navigate the beautiful streets of Belgravia, showing you where
            to shop and dine while exploring the Floral Fairground.
          </p>
        </div>
      </v-card-text>
    </v-card>
    <div class="continue-button">
      <v-btn @click="closeWelcomeDialog" color="primary">Tap to continue</v-btn>
    </div>
  </v-dialog>
</template>
<script>
import titleImage from '@/assets/tutorials/Belgravia_Logo_RGB_DarkGrey.png';
import bellOne from '@/assets/tutorials/Bell_01.png';
export default {
  name: 'WelcomeDialog',
  data() {
    return {
      titleImage,
      bellOne,
    };
  },
  props: {
    showWelcomeDialog: {
      type: Boolean,
      required: true,
    },
    closeWelcomeDialog: {
      type: Function,
      required: true,
    },
  },
};
</script>
